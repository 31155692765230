body {
  padding: 0px;
  margin: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-header > span {
  font-size: calc(6px + 1.5vmin);
}

.App-link {
  color: #61dafb;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.w-1\/3 {
  width: 33.33333%;
}

.w-1\/2 {
  width: 48%;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.relative {
  position: relative;
}

.card-panel {
  height: 150px;
}

.card {
  background-color: white;
  -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
  border-radius: 0.5rem;
}

.min-h-screen {
  min-height: 100vh;
}

#stats > .toolbar {
  background-color: #282c34;
  padding: 12px;
  text-align: left;
  color: white;
}

#stats .sidebar {
  width: 13.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding-top: 5.75rem;
  background-image: -webkit-gradient(linear,left bottom,left top,from(#7e8ea1),to(#282c34));
  background-image: linear-gradient(0deg,#7e8ea1,#282c34);
  background-attachment: fixed;
  text-align: left;
}

#stats a {
  text-decoration: none;
  color: #282c34;
  font-weight: bold;
}

#stats a:hover {
  color: #7e8ea1;
}
  

#stats .sidebar a {
  text-decoration: none;
  font-size: 16px;
  color: #eee;
  display: block;
  padding: 12px 0;
}

#stats .sidebar a:hover {
  color: #bbb;
}

#stats .content {
  min-width: 66.25rem;
  width: 100%;
  max-width: calc(100vw - 13.75rem);
  padding: 3.125rem;
  padding-top: 6.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  background-color: #eee;
}